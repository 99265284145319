import React, { useEffect, useState } from "react";
import { Form, Modal, message } from "antd";
import CustomInput from "../formElements/InputField";
import { forgotPassword } from "../../api/auth";

function ForgotPasswordModal({ modal, setLoginModal, setForgotPasswordModal }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const handleLogin = async (values) => {
    setLoading(true);
    let res;
    try {
      const { username } = values;
      res = await forgotPassword({ email: username });
      if (res) {
        setLoading(false);
        messageApi.open({
          type: "success",
          content: "A verification link has been sent to your email",
          onClose: () => {
            setForgotPasswordModal(false);
            setLoginModal(false);
          },
        });
      }
    } catch (error) {
      const err = error?.response?.data?.email
        ? error?.response?.data?.email[0]
        : "Something went wrong";
      form.resetFields();
      setLoading(false);
      messageApi.open({
        type: "error",
        content: err,
      });
    }
  };

  useEffect(() => {
    return () => clearTimeout();
  });

  const toggleModal = () => {
    setForgotPasswordModal((prev) => !prev);
  };

  const handleLoginModal = () => {
    setLoginModal(true);
    setForgotPasswordModal(false);
  };

  return (
    <Modal
      title="Password Reset"
      open={modal}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleLogin(values);
          })
          .catch((err) => {
            console.log(err);
          });
      }}
      onCancel={toggleModal}
      okButtonProps={{
        className: "actionBtn",
      }}
      okText={loading ? "Submiting ..." : "Submit"}
    >
      {contextHolder}
      <Form form={form} onFinish={handleLogin} className="mt-8">
        <Form.Item
          name="username"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <CustomInput
            labelText="Please enter your email address"
            type="email"
            placeholder="you@example.com"
          />
        </Form.Item>

        <h3 className="flex justify-end text-white">
          <span
            onClick={handleLoginModal}
            className="underline-none hover:underline font-bold text-indigo-200 ml-3"
          >
            Login
          </span>
        </h3>
      </Form>
    </Modal>
  );
}

export default ForgotPasswordModal;
