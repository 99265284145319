export const COULUMN_HEADINGS = [
  {
    title: "Day",
    dataIndex: "day_of_week",
    key: "day_of_week",
  },
  {
    title: "Kitchen Open",
    dataIndex: "kitchen_open",
    key: "kitchen_open",
  },
  {
    title: "Kitchen Close",
    dataIndex: "kitchen_close",
    key: "kitchen_close",
  },
  {
    title: "Delivery Open",
    dataIndex: "delivery_open",
    key: "delivery_open",
  },
  {
    title: "Delivery Close",
    dataIndex: "delivery_close",
    key: "delivery_close",
  },
];

export const ABOUT = {
  name: "Mughali Restaurant Athlone",
  address: "unit 3 Dublin Rd, Kilmacuagh (Cooke), Athlone, Co. Westmeath",
  address_url: "https://maps.app.goo.gl/3z4dyF7uz5y7bNiq6",
  phone_number: "089 9600867",
  opening_hours: [
    {
      day_of_week: "Monday",
      kitchen_open: "19:16",
      kitchen_close: "19:16",
      delivery_open: "19:16",
      delivery_close: "19:16",
    },
    {
      day_of_week: "Tuesday",
      kitchen_open: "19:16",
      kitchen_close: "19:16",
      delivery_open: "19:16",
      delivery_close: "19:16",
    },
    {
      day_of_week: "Wednesday",
      kitchen_open: "19:16",
      kitchen_close: "19:16",
      delivery_open: "19:16",
      delivery_close: "19:16",
    },
    {
      day_of_week: "Thursday",
      kitchen_open: "19:16",
      kitchen_close: "19:16",
      delivery_open: "19:16",
      delivery_close: "19:16",
    },
    {
      day_of_week: "Friday",
      kitchen_open: "19:16",
      kitchen_close: "19:16",
      delivery_open: "19:16",
      delivery_close: "19:16",
    },
    {
      day_of_week: "Saturday",
      kitchen_open: "19:16",
      kitchen_close: "19:16",
      delivery_open: "19:16",
      delivery_close: "19:16",
    },
    {
      day_of_week: "Sunday",
      kitchen_open: "19:16",
      kitchen_close: "19:16",
      delivery_open: "19:16",
      delivery_close: "19:17",
    },
  ],
};

export const MENU = [
  {
    id: 1,
    items: [
      {
        id: 1,
        name: "Daal",
        required_items: [
          {
            id: 1,
            item: [
              {
                id: 3,
                name: "Coke Regular",
                price: null,
              },
              {
                id: 4,
                name: "Sprite Regular",
                price: null,
              },
            ],
            description: "Drinks",
          },
          {
            id: 2,
            item: [
              {
                id: 2,
                name: "Rotti",
                price: "2.00",
              },
              {
                id: 6,
                name: "Naan",
                price: "3.00",
              },
            ],
            description: "Rotties",
          },
        ],
        optional_items: [
          {
            id: 5,
            name: "Raita",
            price: "2.00",
          },
        ],
        description: "Dall rotti",
        is_vegitarian: false,
        show_salad: false,
        show_sauce: false,
        allow_serving: false,
        price: "8.90",
        image: "https://zaman.pythonanywhere.com/item_images/Screenshot_2024-05-09_at_3.25.45_PM_uE33ih7.png",
        is_meal: true,
        spiciness_level: 0,
        is_available: true,
        item: 1,
        category: 1,
      },
      {
        id: 5,
        name: "Doner Kebab Meal Great Offer",
        required_items: [
          {
            id: 1,
            item: [
              {
                id: 3,
                name: "Coke Regular",
                price: null,
              },
              {
                id: 4,
                name: "Sprite Regular",
                price: null,
              },
            ],
            description: "Drinks",
          },
        ],
        optional_items: [],
        description: "2 Doner kebab with chips and 2 can",
        is_vegitarian: true,
        show_salad: true,
        show_sauce: true,
        allow_serving: false,
        price: "30.00",
        image: null,
        is_meal: true,
        spiciness_level: null,
        is_available: true,
        item: 7,
        category: 1,
      },
      {
        id: 6,
        name: "Wraps Meal Deals",
        required_items: [
          {
            id: 3,
            item: [
              {
                id: 9,
                name: "Falafel Wrap with Fries",
                price: null,
              },
              {
                id: 10,
                name: "Goujon wrap with fries",
                price: null,
              },
              {
                id: 11,
                name: "Doner wrap with fries",
                price: null,
              },
            ],
            description: "Wraps",
          },
        ],
        optional_items: [
          {
            id: 3,
            name: "Coke Regular",
            price: null,
          },
        ],
        description: "Falafel wrap with fries and can/ goujon wrap with fries and can/ doner wrap with fries and can",
        is_vegitarian: false,
        show_salad: true,
        show_sauce: true,
        allow_serving: false,
        price: "55.00",
        image: null,
        is_meal: true,
        spiciness_level: 1,
        is_available: true,
        item: 8,
        category: 1,
      },
    ],
    name: "Meal Deals",
    description: "",
  },
  {
    id: 2,
    items: [
      {
        id: 2,
        name: "Rotti",
        required_items: [],
        optional_items: [],
        description: "Rotti ay",
        is_vegitarian: false,
        show_salad: false,
        show_sauce: false,
        allow_serving: false,
        price: "2.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 2,
        category: 2,
      },
      {
        id: 3,
        name: "Naan",
        required_items: [],
        optional_items: [],
        description: "Naan ay",
        is_vegitarian: false,
        show_salad: false,
        show_sauce: false,
        allow_serving: false,
        price: "3.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 6,
        category: 2,
      },
    ],
    name: "Rotties",
    description: "",
  },
  {
    id: 3,
    items: [
      {
        id: 4,
        name: "Raita",
        required_items: [],
        optional_items: [],
        description: "Raita jjj",
        is_vegitarian: false,
        show_salad: false,
        show_sauce: false,
        allow_serving: false,
        price: "2.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 5,
        category: 3,
      },
    ],
    name: "Chatni",
    description: "",
  },
  {
    id: 4,
    items: [],
    name: "Mughali Thaal",
    description: "",
  },
  {
    id: 5,
    items: [],
    name: "Mains",
    description: "",
  },
  {
    id: 6,
    items: [],
    name: "BBQ",
    description: "",
  },
  {
    id: 7,
    items: [],
    name: "Burgers & Wraps",
    description: "",
  },
  {
    id: 8,
    items: [
      {
        id: 7,
        name: "Aloo Bhujia",
        required_items: [],
        optional_items: [],
        description: "Diced potatoes cooked with fenugreek, cumin, & fried onions",
        is_vegitarian: true,
        show_salad: false,
        show_sauce: false,
        allow_serving: true,
        price: "22.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 14,
        category: 8,
        servings: [
          {
            id: 0,
            name: "Regular",
            price: 22.0,
          },
          {
            id: 1,
            name: "Large",
            price: 44.0,
          },
        ],
      },
      {
        id: 8,
        name: "Chana Masala",
        required_items: [],
        optional_items: [],
        description: "Chickpeas sauteed with tomatoes, fresh ginger, garlic, fenugreek, black peppercorns, garam masala and fresh coriander",
        is_vegitarian: true,
        show_salad: false,
        show_sauce: false,
        allow_serving: true,
        price: "10.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 15,
        category: 8,
        servings: [
          {
            id: 0,
            name: "Regular",
            price: 10.0,
          },
          {
            id: 1,
            name: "Large",
            price: 20.0,
          },
        ],
      },
      {
        id: 9,
        name: "Bhindi Bhaji",
        required_items: [],
        optional_items: [],
        description: "Chopped okra cooked with onions, fresh tomatoes, fresh ginger and garam masala",
        is_vegitarian: true,
        show_salad: false,
        show_sauce: false,
        allow_serving: true,
        price: "13.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 16,
        category: 8,
        servings: [
          {
            id: 0,
            name: "Regular",
            price: 13.0,
          },
          {
            id: 1,
            name: "Large",
            price: 26.0,
          },
        ],
      },
      {
        id: 10,
        name: "Bangahn Aloo",
        required_items: [],
        optional_items: [],
        description: "Aubergine and potatoes with fresh tomato, fresh ginger, garlic and kalwanji seed",
        is_vegitarian: true,
        show_salad: false,
        show_sauce: false,
        allow_serving: true,
        price: "14.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 17,
        category: 8,
        servings: [
          {
            id: 0,
            name: "Regular",
            price: 14.0,
          },
          {
            id: 1,
            name: "Large",
            price: 28.0,
          },
        ],
      },
      {
        id: 11,
        name: "Vegetable Paneer Masala",
        required_items: [],
        optional_items: [],
        description: "Mixed vegetable dish with Indian cottage cheese",
        is_vegitarian: true,
        show_salad: false,
        show_sauce: false,
        allow_serving: true,
        price: "16.00",
        image: null,
        is_meal: false,
        spiciness_level: null,
        is_available: true,
        item: 18,
        category: 8,
        servings: [
          {
            id: 0,
            name: "Regular",
            price: 16.0,
          },
          {
            id: 1,
            name: "Large",
            price: 32.0,
          },
        ],
      },
    ],
    name: "Vegan & Vegetarian",
    description: "",
  },
  {
    id: 9,
    items: [],
    name: "Small Bites",
    description: "",
  },
  {
    id: 10,
    items: [],
    name: "Sundries & Dips",
    description: "",
  },
  {
    id: 11,
    items: [],
    name: "Others",
    description: "",
  },
];

export const spiceLevelsIcon = [
  {
    id: 1,
    icon: "/static/frontend/images/mild-icon.png",
  },
  {
    id: 2,
    icon: "/static/frontend/images/medium-icon.png",
  },
  {
    id: 3,
    icon: "/static/frontend/images/hot-icon.png",
  },
];
