import React from "react";

export const Footer = () => {
  return (
    <footer className="footer mb-10  text-center">
      <div className="footer-bottom container">
        <p className="copyright">
          &copy; 2024 Mughali Restaurant. | Crafted by{" "}
          <a
            // href="https://github.com/codewithsadee"
            target="_blank"
            className="link"
          >
            Zaman Chaudhary
          </a>
        </p>
      </div>
    </footer>
  );
};
