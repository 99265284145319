import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Home } from "./pages/Home";
import { Cart } from "./pages/cart";
import LayoutWrapper from "./components/layout/layoutWrapper";
import Preloader from "./components/preloader";
import { ABOUT } from "./constants";
import { aboutRestaurant, getDeliveryDetails } from "./api/other";
import checkTokenExpiration from "./utils/utils";
import { OrderHistory } from "./pages/OrderHistory";

function App() {
  return (
    <Router>
      <AppRoutes />
    </Router>
  );
}

function AppRoutes() {
  const [totalItems, setTotalItems] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [aboutData, setAboutData] = useState();
  const [deliveryDetails, setDeliveryDetails] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get("session_id");

  const fetchData = async () => {
    try {
      const res = await aboutRestaurant();
      const deliveryTime = await getDeliveryDetails();

      //const res = ABOUT;
      setAboutData(res);
      setDeliveryDetails(deliveryTime?.delivery_time_in_min);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    checkTokenExpiration();
  }, []);

  useEffect(() => {
    const cart = JSON.parse(localStorage.getItem("cart"));
    fetchData();
    if (cart) {
      setTotalItems(cart.reduce((sum, item) => sum + item.quantity, 0));
      // setTotalItems(cart.length);
    }

    if (document.readyState === "complete") {
      setIsLoaded(true);
    } else {
      const handleLoad = () => setIsLoaded(true);
      window.addEventListener("load", handleLoad);

      return () => window.removeEventListener("load", handleLoad);
    }
  }, []);

  return (
    <div id="top">
      {!isLoaded && <Preloader />}
      <Routes>
        <Route
          path="/"
          element={
            <LayoutWrapper totalItems={totalItems} aboutData={aboutData} />
          }
        >
          <Route
            path="/home"
            element={
              <Home
                deliveryDetails={deliveryDetails}
                setTotalItems={setTotalItems}
                aboutData={aboutData}
                sessionId={sessionId}
              />
            }
          />
          <Route
            path="/cart"
            element={<Cart setTotalItems={setTotalItems} />}
          />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route
            path="/"
            element={
              <Navigate
                to={sessionId ? `/home?session_id=${sessionId}` : "/home"}
              />
            }
          />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
