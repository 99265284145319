import axios from "./axiosConfig";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const axiosPost = async ({ payload, path, token }) => {
  try {
    if (token) {
      token = localStorage.getItem("token");
    }

    const headers = {
      "Content-Type": "application/json",
      // "access-control-allow-origin": "*",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await axios.post(`${BASE_URL}${path}`, payload, {
      headers,
    });

    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const axiosTempPost = async ({ payload, path, token }) => {
  try {
    if (token) {
      token = localStorage.getItem("token");
    }

    const headers = {
      "Content-Type": "application/json",
      // "access-control-allow-origin": "*",
    };

    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    const response = await axios.post(
      `${BASE_URL}${path}`,
      payload,
      {
        headers,
      }
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const axiosGet = async ({ path, token = null }) => {
  try {
    if (token) {
      token = localStorage.getItem("token");
    }
    const headers = {
      "Content-Type": "application/json",
    };
    if (token) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const response = await axios.get(`${BASE_URL}${path}`, {
      headers,
    });
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
};
