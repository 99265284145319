import React, { useState, useEffect } from "react";
import { Tabs, message } from "antd";
import { spiceLevelsIcon } from "../constants";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { menuRestaurant } from "../api/other";
import MenuModal from "./modals/menuModal";

export const MenuItems = ({ setTotalItems }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [cart, setCart] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [menuItems, setMenuItems] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const fetchData = async () => {
    try {
      const res = await menuRestaurant();
      setMenuItems(res);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    setCart(storedCart);
  }, []);

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(cart));
    setTotalItems(cart.reduce((sum, item) => sum + item.quantity, 0));
  }, [cart]);

  const removeFromCart = (itemId) => {
    const updatedCart = cart.filter((item) => item.id !== itemId);
    setCart(updatedCart);
    messageApi.open({
      type: "success",
      content: "Item removed from cart.",
    });
  };

  const handleAddingItem = (item) => {
    setSelectedItem(item);
    setIsMenuOpen(true);
  };

  const isItemInCart = (itemId) => {
    return cart.some((item) => item.id === itemId);
  };

  return (
    <>
      <section className="section menu" aria-label="menu-label" id="menu">
        {contextHolder}
        <div className="container max-w-full">
          <p className="section-subtitle text-center label-2">Special Selection</p>

          <h2 className="headline-1 section-title text-center" style={{ fontFamily: "Forum" }}>Delicious Menu</h2>

          <Tabs
            className="mt-4 w-full px-4"
            type="card"
            items={menuItems?.map((cat) => {
              return {
                label: cat.name,
                key: cat.id,
                children: (
                  <>
                    {cat?.items?.length ? (
                      <ul className="grid-list px-4">
                        {cat.items.map((item) => (
                          <li key={item.name}>
                            <div className="menu-card hover:card"
                              style={{
                                border: 'solid 1px',
                                color: '#e4c590',
                                borderRadius: '25px',
                                padding: '5px'
                              }}>
                              <figure className="card-banner img-holder" style={{ width: 100, height: 100 }}>
                                <img
                                  src={item?.image ? item?.image : "/static/frontend/images/menu-1.png"}
                                  width="100"
                                  height="100"
                                  loading="lazy"
                                  alt={item.name}
                                  className="img-cover"
                                />
                              </figure>

                              <div className="w-full flex justify-between items-center">
                                <div>
                                  <div className="title-wrapper">
                                    <h3 className="title-3">
                                      <a className="card-title" style={{ fontFamily: 'sans-serif' }}
                                        onClick={() => {
                                            handleAddingItem(item);
                                        }}>
                                        {item.name}
                                      </a>
                                    </h3>
                                    <span className="span title-2">€{item.price}</span>
                                  </div>

                                  <p className="card-text label-1 mt-3">{item.description}</p>

                                  <div className="text-white flex gap-2 items-center mt-2">
                                    {item.is_vegitarian && (
                                      <span className="flex">
                                        Vegetarian <img className="ml-3" width={30} src="/static/frontend/images/vegetarian.png" />
                                      </span>
                                    )}

                                    {item.spiciness_level ? (
                                      <span className="flex">
                                        <img width={70} src={spiceLevelsIcon.find((el) => el.id === item.spiciness_level).icon} />
                                      </span>
                                    ) : null}
                                  </div>

                                  {/* <div className="flex justify-start items-center mt-3">
                                  {item.show_salad && (
                                    <div className="text-white flex items-center mt-2">
                                      {" "}
                                      Salad <img className="ml-3" width={30} src="/static/frontend/images/salad.png" />
                                    </div>
                                  )}
                                  {item.show_sauce && (
                                    <div className="text-white flex items-center mt-2 ml-6">
                                      {" "}
                                      Sauce <img className="ml-3" width={30} src="/static/frontend/images/ketchup.png" />
                                    </div>
                                  )}
                                </div> */}
                                </div>
                                <div
                                  className="actionBtn p-1 rounded-full ml-12 cursor-pointer"
                                  onClick={() => {
                                    if (isItemInCart(item.id)) {
                                      removeFromCart(item.id);
                                    } else {
                                      handleAddingItem(item);
                                    }
                                  }}
                                >
                                  {isItemInCart(item.id) ? <MinusCircleOutlined className="mt-2" /> : <PlusCircleOutlined className="mt-2" />}
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <h2 className="headline-1 section-title text-center mt-10 pt-10">No Items</h2>
                    )}
                  </>
                ),
              };
            })}
          />

          {/* <p className="menu-text text-center">
            During winter daily from <span className="span">7:00 pm</span> to <span className="span">9:00 pm</span>
          </p> */}

          <img src="/static/frontend/images/shape-5.png" width="921" height="1036" loading="lazy" alt="shape" className="shape shape-2 move-anim" />
          <img src="/static/frontend/images/shape-6.png" width="343" height="345" loading="lazy" alt="shape" className="shape shape-3 move-anim" />
        </div>
      </section>
      <MenuModal key={Math.random()} modal={isMenuOpen} cart={cart} setCart={setCart} setIsMenuOpen={setIsMenuOpen} item={selectedItem} />
    </>
  );
};

export default MenuItems;
