import React from "react";
import { Input } from "antd";

export default function CustomInput({
  type,
  placeholder,
  onChange,
  icon,
  labelText,
}) {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <>
      {labelText && (
        <label className="block font-medium w-full mb-1 ml-1 text-white">
          {labelText}
        </label>
      )}

      {type !== "password" ? (
        <Input
          prefix={icon}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
          className="w-full px-3 py-2 text-gray-800 bg-white outline-none focus:border-indigo-600 shadow-sm rounded-xl"
        />
      ) : (
        <Input.Password
          prefix={icon}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
          className="w-full px-3 py-2 text-gray-800 bg-white outline-none focus:border-indigo-600 shadow-sm rounded-xl"
        />
      )}
    </>
  );
}
