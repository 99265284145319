import React from "react";

export const StrengthSection = () => {
  return (
    <section className="section features text-center" aria-label="features" id="about">
      <div className="container max-w-full">
        <p className="section-subtitle label-2">Why Choose Us</p>

        <h2 className="headline-1 section-title">Our Strength</h2>

        <ul className="grid-list">
          <li className="feature-item">
            <div className="feature-card">
              <div className="card-icon">
                <img src="/static/frontend/images/features-icon-1.png" width="100" height="80" loading="lazy" alt="icon" />
              </div>

              <h3 className="title-2 card-title" style={{ fontFamily: 'sans-serif' }}>Hygienic Food</h3>

              <p className="label-1 card-text">Serving meticulously prepared meals with the highest standards of cleanliness.</p>
            </div>
          </li>

          <li className="feature-item">
            <div className="feature-card">
              <div className="card-icon">
                <img src="/static/frontend/images/features-icon-2.png" width="100" height="80" loading="lazy" alt="icon" />
              </div>

              <h3 className="title-2 card-title" style={{ fontFamily: 'sans-serif' }}>Fresh Environment</h3>

              <p className="label-1 card-text">Dine in a refreshing and welcoming atmosphere that invigorates the senses.</p>
            </div>
          </li>

          <li className="feature-item">
            <div className="feature-card">
              <div className="card-icon">
                <img src="/static/frontend/images/features-icon-3.png" width="100" height="80" loading="lazy" alt="icon" />
              </div>

              <h3 className="title-2 card-title" style={{ fontFamily: 'sans-serif' }}>Skilled Chefs</h3>

              <p className="label-1 card-text">Experience culinary excellence crafted by our expert chefs.</p>
            </div>
          </li>

          <li className="feature-item">
            <div className="feature-card">
              <div className="card-icon">
                <img src="/static/frontend/images/features-icon-4.png" width="100" height="80" loading="lazy" alt="icon" />
              </div>

              <h3 className="title-2 card-title" style={{ fontFamily: 'sans-serif' }}>Event & Party</h3>

              <p className="label-1 card-text">Perfect venue for unforgettable events and celebrations.</p>
            </div>
          </li>
        </ul>

        <img src="/static/frontend/images/shape-7.png" width="208" height="178" loading="lazy" alt="shape" className="shape shape-1" />

        <img src="/static/frontend/images/shape-8.png" width="120" height="115" loading="lazy" alt="shape" className="shape shape-2" />
      </div>
    </section>
  );
};
