import React, { useEffect, useState, useRef } from "react";

export const HeroSlider = () => {
  const [currentSlidePos, setCurrentSlidePos] = useState(0);
  const heroSliderItemsRef = useRef([]);
  const heroSliderPrevBtnRef = useRef(null);
  const heroSliderNextBtnRef = useRef(null);

  const updateSliderPos = (index) => {
    heroSliderItemsRef.current.forEach((item, idx) => {
      if (item) {
        if (idx === index) {
          item.classList.add("active");
        } else {
          item.classList.remove("active");
        }
      }
    });
  };

  const slideNext = () => {
    setCurrentSlidePos((prev) => (prev >= heroSliderItemsRef.current.length - 1 ? 0 : prev + 1));
  };

  const slidePrev = () => {
    setCurrentSlidePos((prev) => (prev <= 0 ? heroSliderItemsRef.current.length - 1 : prev - 1));
  };

  useEffect(() => {
    updateSliderPos(currentSlidePos);
  }, [currentSlidePos]);

  useEffect(() => {
    const nextBtn = heroSliderNextBtnRef.current;
    const prevBtn = heroSliderPrevBtnRef.current;

    nextBtn?.addEventListener("click", slideNext);
    prevBtn?.addEventListener("click", slidePrev);

    const slideAutomatically = () => {
      const intervalId = setInterval(slideNext, 5000);

      return () => clearInterval(intervalId);
    };

    const intervalId = slideAutomatically();

    return () => {
      nextBtn?.removeEventListener("click", slideNext);
      prevBtn?.removeEventListener("click", slidePrev);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <section className="hero text-center" aria-label="home" id="home">
      <ul className="hero-slider" data-hero-slider>
        {["mughali-hero-image-1.jpg", "mughali-hero-image-2.jpg", "mughali-hero-image-3.jpg"].map((image, index) => (
          <li
            className={`slider-item ${index === 0 ? "active" : ""}`}
            data-hero-slider-item
            key={index}
            ref={(el) => (heroSliderItemsRef.current[index] = el)}
          >
            <div className="slider-bg relative">
              <img src={`/static/frontend/images/${image}`} width="1880" height="950" alt="" className="img-cover " />
              <div className="overlay" style={{ width: "1880px", height: "950px" }}></div>
            </div>
            <p className="label-2 section-subtitle slider-reveal">
              {index === 0 && "Tradational & Hygine"}
              {index === 1 && "delightful experience"}
              {index === 2 && "amazing & delicious"}
            </p>
            <h1 className="display-1 hero-title slider-reveal" style={{ fontFamily: "Forum" }}>
              {index === 0 && (
                <>
                  For the love of <br />
                  delicious food
                </>
              )}
              {index === 1 && (
                <>
                  Flavors Inspired by <br />
                  the Seasons
                </>
              )}
              {index === 2 && (
                <>
                  Where every flavor <br />
                  tells a story
                </>
              )}
            </h1>
            <p className="body-2 hero-text slider-reveal">Come with family & feel the joy of mouthwatering food</p>
          </li>
        ))}
      </ul>

      <button className="slider-btn prev" aria-label="slide to previous" data-prev-btn ref={heroSliderPrevBtnRef}>
        <ion-icon name="chevron-back" />
      </button>

      <button className="slider-btn next" aria-label="slide to next" data-next-btn ref={heroSliderNextBtnRef}>
        <ion-icon name="chevron-forward" />
      </button>

      <a className="hero-btn has-after">
        <img src="/static/frontend/images/halal-icon.png" width="85" height="85" alt="Halal icon" />
        {/* <span className="label-2 text-center span"></span> */}
      </a>
    </section>
  );
};
