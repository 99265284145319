import React, { useEffect } from "react";
import { Form, Modal, message } from "antd";
import CustomInput from "../formElements/InputField";
import { loginUser } from "../../api/auth";

function LoginModal({
  modal,
  setLoginModal,
  setSignupModal,
  setForgotPasswordModal,
}) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const handleLogin = async (values) => {
    let res;
    try {
      const { username, password } = values;
      res = await loginUser({ username, password });
      if (res?.access) {
        localStorage.setItem("token", res?.access);
        messageApi.open({
          type: "success",
          content: "User logged In!",
          onClose: () => {
            setSignupModal(false);
            setLoginModal(false);
          },
        });
      }
    } catch (error) {
      form.resetFields();
      messageApi.open({
        type: "error",
        content: res?.detail || "Credentails not valid or something went wrong",
      });
    }
  };

  useEffect(() => {
    return () => clearTimeout();
  });

  const toggleModal = () => {
    setLoginModal((prev) => !prev);
  };

  const handleLoginModal = () => {
    setLoginModal(false);
    setSignupModal(true);
  };

  const handleForgotPasswordModal = () => {
    setLoginModal(false);
    setForgotPasswordModal(true);
  };

  return (
    <Modal
      title="Customer Login"
      open={modal}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            handleLogin(values);
          })
          .catch((err) => {
            console.log(err);
          });
      }}
      onCancel={toggleModal}
      okButtonProps={{
        className: "actionBtn",
      }}
      okText="Login"
    >
      {contextHolder}
      <Form form={form} onFinish={handleLogin} className="mt-8">
        <Form.Item
          name="username"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <CustomInput
            labelText=" Email address"
            type="email"
            placeholder="you@example.com"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <CustomInput
            labelText="Password"
            type="password"
            placeholder="*******"
          />
        </Form.Item>

        <div className="flex justify-between text-white">
          <h3
            onClick={handleForgotPasswordModal}
            className="underline-none hover:underline font-bold text-indigo-200 cursor-pointer"
          >
            Forgot Password?
          </h3>
          <h3 className="flex">
            Don't have an account?{" "}
            <span
              onClick={handleLoginModal}
              className="underline-none hover:underline font-bold text-indigo-200 ml-3"
            >
              Sign Up
            </span>
          </h3>
        </div>
      </Form>
    </Modal>
  );
}

export default LoginModal;
