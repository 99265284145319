import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // If the request succeeds, return the response directly
    return response;
  },
  (error) => {
    // Handle errors
    if (error.response) {
      console.log("error", error);
      // The request was made and the server responded with a status code 401
      if (error.response.status === 401) {
        if (localStorage.getItem("token") !== null) {
          localStorage.removeItem("token");
        }
        window.location.href = "/";
      }
    }
    // Return the error
    return Promise.reject(error);
  }
);

export default axiosInstance;
