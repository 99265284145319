import { axiosPost } from "../../utils/axiosMethod";

export const loginUser = async (payload) => {
  const res = await axiosPost({
    path: "restaurant/login/",
    payload,
  });

  return res?.data;
};

export const signupUser = async (payload) => {
  const res = await axiosPost({
    path: "restaurant/register/",
    payload,
  });

  return res?.data;
};

export const forgotPassword = async (payload) => {
  const res = await axiosPost({
    path: "restaurant/password_reset/",
    payload,
  });

  return res?.data;
};
