import React, { useState } from "react";
import { Form, Modal, message } from "antd";
import CustomInput from "../formElements/InputField";
import { signupUser } from "../../api/auth";

function SignupModal({ modal, setSignupModal, setLoginModal }) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);

  const handleSignup = async (values) => {
    setLoading(true);
    try {
      const { email, password, firstName, lastName } = values;
      const res = await signupUser({
        email,
        password,
        first_name: firstName,
        last_name: lastName,
      });
      if (res?.access) {
        form.resetFields();
        localStorage.setItem("token", res?.access);
        messageApi.open({
          type: "success",
          content: "User signed Up!",
          onClose: () => {
            setSignupModal(false);
            setLoginModal(false);
          },
        });
      }
    } catch (error) {
      const msg = error?.response?.data?.email[0] ?? "Error in Signing Up!";
      messageApi.open({
        type: "error",
        content: msg,
      });
    }
    setLoading(false);
  };

  const toggleModal = () => {
    setSignupModal((prev) => !prev);
  };

  const handleSignUpModal = () => {
    setSignupModal(false);
    setLoginModal(true);
  };

  return (
    <Modal
      title="Customer Sign Up"
      open={modal}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            handleSignup(values);
          })
          .catch((err) => {
            console.log(err);
          });
      }}
      onCancel={toggleModal}
      okButtonProps={{
        className: "actionBtn",
      }}
      okText={loading ? "Processing ..." : "Sign Up"}
    >
      {contextHolder}
      <Form form={form} onFinish={handleSignup} className="mt-8">
        <div className="flex justify-between">
          <Form.Item
            name="firstName"
            className="w-full"
            rules={[
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <CustomInput
              labelText="First Name"
              type="text"
              placeholder="John"
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            className="w-full ml-6"
            rules={[
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <CustomInput labelText="Last Name" type="text" placeholder="Doe" />
          </Form.Item>
        </div>
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <CustomInput
            labelText=" Email address"
            type="email"
            placeholder="you@example.com"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <CustomInput
            labelText="Password"
            type="password"
            placeholder="*******"
          />
        </Form.Item>

        <h3 className="flex justify-end text-white">
          Already have an account?{" "}
          <span
            onClick={handleSignUpModal}
            className="underline-none hover:underline font-bold text-indigo-200  ml-3"
          >
            Login here
          </span>
        </h3>
      </Form>
    </Modal>
  );
}

export default SignupModal;
