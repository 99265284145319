import React, { useState, useEffect } from "react";
import { Button, message, Form, Input, Tabs } from "antd";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { spiceLevelsIcon } from "../constants";
import "./cart.css";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  createPaymentIntent,
  getDeliveryChargeDetails,
  getDiscountDetails,
  publishOrder,
} from "../api/other";

const westmeathBounds = {
  north: 53.683,
  south: 53.3168,
  east: -6.8283,
  west: -7.5702,
};

const checkIfUserLoggedIn = () => {
  if (localStorage.getItem("token")) {
    return true;
  }

  return false;
};
export const Cart = ({ setTotalItems }) => {
  const [cashOnDeliveryLoader, setCashOnDeliveryLoader] = useState(false);
  const [payNowLoader, setPayNowLoader] = useState(false);
  const [orderType, setOrderType] = useState(0);
  const [cart, setCart] = useState([]);
  const [totalBill, setTotalBill] = useState(0);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [address, setAddress] = useState("");
  const [deliveryCharges, setDeliveryCharges] = useState(3);
  const [discountDetail, setDiscountDetails] = useState({});
  const [showDiscountText, setShowDiscountText] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const getDeliveryChargeHandler = async () => {
    try {
      const response = await getDeliveryChargeDetails();
      setDeliveryCharges(Number(response.delivery_rate).toFixed(2));
    } catch (err) {
      console.log("delivery charge err:", err);
    }
  };

  const getDeliveryInfoHandler = async () => {
    try {
      const res = await getDiscountDetails();
      setDiscountDetails(JSON.parse(res?.discount_on_order));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem("cart")) || [];
    const total = calculateBill(storedCart);
    setTotalBill(total);
    setCart(storedCart);
    getDeliveryChargeHandler();
    getDeliveryInfoHandler();
  }, []);

  useEffect(() => {
    const cartForStorage = cart.filter((item) => item.quantity > 0);
    localStorage.setItem("cart", JSON.stringify(cartForStorage));
    const total = calculateBill(cart);
    setTotalBill(total);
    setTotalItems(cart.reduce((sum, item) => sum + item.quantity, 0));
  }, [cart, orderType]);

  // useEffect(() => {
  //   const checkTime = () => {
  //     const now = new Date();
  //     const currentHour = now.getHours();

  //     // Disable buttons from 11 PM to 2 PM
  //     if ((currentHour >= 23 && currentHour <= 23) || (currentHour >= 0 && currentHour < 14)) {
  //       setIsDisabled(true);
  //     } else {
  //       setIsDisabled(false);
  //     }
  //   };

  //   checkTime();
  //   const interval = setInterval(checkTime, 60000); // Check every minute

  //   return () => clearInterval(interval);
  // }, []);

  const calculateBill = (items) => {
    let totalBill = 0;
    items.map((singleMenu) => {
      if (singleMenu.serving === 1) {
        totalBill = totalBill + Number(singleMenu.price) * singleMenu.quantity;
      } else {
        totalBill =
          totalBill + Number(singleMenu.large_price) * singleMenu.quantity;
      }
    });

    // if there is any discount
    setShowDiscountText(false);
    if (discountDetail && totalBill >= discountDetail.amount) {
      const discount = (discountDetail.discount / 100) * totalBill;
      totalBill = totalBill - discount;
      setShowDiscountText(true);
    }
    // if delivery is selected
    if (orderType === 1) {
      totalBill = totalBill + Number(deliveryCharges);
    }

    return Number(totalBill).toFixed(2);
  };

  const incrementQuantity = (itemId, serving) => {
    const updatedCart = cart.map((item) =>
      item.id === itemId && serving === item.serving
        ? { ...item, quantity: item.quantity + 1 }
        : item
    );
    setCart(updatedCart);
  };

  const decrementQuantity = (itemId, serving) => {
    const updatedCart = cart.map((item) =>
      item.id === itemId && serving === item.serving
        ? { ...item, quantity: Math.max(0, item.quantity - 1) }
        : item
    ).filter((item) => item.quantity > 0);
    setCart(updatedCart);
  };

  const backToHome = () => {
    navigate("/");
  };

  const handleAddressChange = (address) => {
    setAddress(address);
  };

  const handleAddressSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        console.log(address);
        console.log(results);
        const addressComponents = results[0].address_components;
        const city = addressComponents.find((component) =>
          component.types.includes("locality")
        )?.long_name;
        const zipCode = addressComponents.find((component) =>
          component.types.includes("postal_code")
        )?.long_name;
        const formattedAddress = results[0].formatted_address;

        setAddress(formattedAddress);
        form.setFieldsValue({ address: formattedAddress });
        form.setFieldsValue({ city });
        form.setFieldsValue({ zip_code: zipCode });
      })
      .catch((error) => console.error("Error", error));
  };

  const handleSubmit = (paymentMethod) => {
    if (!checkIfUserLoggedIn()) {
      messageApi.open({
        type: "error",
        content: `Please login or signup to place order!`,
      });
      return;
    }
    form
      .validateFields()
      .then((values) => {
        values.address = address;
        const addressComponents = values.address.split(",");
        const city = addressComponents[addressComponents.length - 3]?.trim();
        const zipCode = addressComponents[addressComponents.length - 2]
          ?.split(" ")
          .pop()
          .trim();
        const addressWithFlat = values.flat
          ? `${values.flat}, ${values.address}`
          : values.address;
        const checkoutData = {
          address: addressWithFlat,
          city: city || "",
          zip_code: zipCode || "",
          phone: values.phone,
          payment_method: paymentMethod
        };

        form.resetFields();
        placeOrder(checkoutData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleCheckout = async (sessionId) => {
  //   setLoading(true);
  //   try {
  //     const stripe = await stripePromise;

  //     const result = await stripe.redirectToCheckout({
  //       sessionId: "cs_test_a10jCfechEfQ7UtJ4RNCOARjLv9Nod5dtg98erIcoFGkfWwvetF41n6qmi",
  //     });

  //     if (result.error) {
  //       setLoading(false);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const placeOrder = async (checkoutData) => {
    try {
      if (checkoutData.payment_method === 1) {
        setPayNowLoader(true);
      } else {
        setCashOnDeliveryLoader(true);
      }
      const order_items = cart.map((item) => {
        return {
          menu_item: item?.id,
          note: item?.notes || "",
          serving: item?.serving,
          spice_level: item?.spiceLevel,
          additional_items: item.additional_items,
          required_items: item.required_items,
          quantity: item.quantity,
        };
      });
      // assuming 0 means delivery and 1 means pickup
      const payload = {
        order_type: orderType,
        order_items,
        ...checkoutData,
      };

      const res = await publishOrder(payload);
      // console.log(res, payload, checkoutData);
      // console.log(payload.payment_method === 1, payload.payment_method == 1)
      if (payload.payment_method === 1) {
        window.location.href = res;
      }

      messageApi.open({
        type: "success",
        content: `Your order is placed.`,
      });

      // Clear the cart
      setCart([]);
      localStorage.removeItem("cart");
      setTotalItems(0);
    } catch (err) {
      messageApi.open({
        type: "error",
        content: `Something went wrong!`,
      });
    } finally {
      setCashOnDeliveryLoader(false);
      setPayNowLoader(false);
    }
  };

  const returnPrice = (menu) => {
    if (menu.serving === 1) return menu.price;
    return menu.large_price;
  };

  return (
    <section
      className="section menu mt-[100px] flex justify-center"
      aria-label="menu-label"
      id="menu"
    >
      {contextHolder}
      <div className="container px-4">
        <h2 className="headline-1 section-title text-center">Cart Items</h2>

        {cart.length > 0 ? (
          <ul className="grid-list">
            {cart.map((item) => (
              <li key={item.id}>
                <div className="menu-card hover:card">
                  <figure
                    className="card-banner img-holder"
                    style={{ width: 100, height: 100 }}
                  >
                    <img
                      src={
                        item?.image
                          ? item?.image
                          : "/static/frontend/images//menu-1.png"
                      }
                      width="100"
                      height="100"
                      loading="lazy"
                      alt={item.name}
                      className="img-cover"
                    />
                  </figure>

                  <div className="flex justify-between ">
                    <div>
                      <div className="title-wrapper">
                        <h3 className="title-3">
                          <a href="#" className="card-title" style={{ fontFamily: 'sans-serif' }}>
                            {item.name}
                          </a>
                        </h3>

                        <span className="span title-2">
                          €{Number(returnPrice(item)).toFixed(2)}
                        </span>
                      </div>

                      <p className="card-text label-1 mt-3">
                        {item.description}
                      </p>
                      {item.is_vegitarian && (
                        <div className="text-white flex items-center mt-2">
                          Vegetarian{" "}
                          <img
                            className="ml-3"
                            width={30}
                            src="/static/frontend/images//vegetarian.png"
                          />
                        </div>
                      )}
                      <div className="flex justify-start items-center mt-2">
                        {item.show_salad && (
                          <div className="text-white flex items-center">
                            {" "}
                            Salad{" "}
                            <img
                              className="ml-3"
                              width={30}
                              src="/static/frontend/images//salad.png"
                            />
                          </div>
                        )}
                        {item.show_sauce && (
                          <div className="text-white flex items-center mt-2 ml-6">
                            {" "}
                            Sauce{" "}
                            <img
                              className="ml-3"
                              width={30}
                              src="/static/frontend/images//ketchup.png"
                            />
                          </div>
                        )}
                      </div>
                      <div className="flex justify-start mt-2">
                        {item?.spiceLevel ? (
                          <span className="flex">
                            <img
                              width={70}
                              src={
                                spiceLevelsIcon.find(
                                  (el) => el.id === item.spiceLevel
                                ).icon
                              }
                            />
                          </span>
                        ) : null}
                      </div>
                    </div>

                    <div className="cursor-pointer ml-12 flex items-start">
                      <button
                        className="mr-5 actionBtn rounded-full px-2"
                        onClick={() => incrementQuantity(item.id, item.serving)}
                      >
                        <PlusOutlined />
                      </button>
                      <span className="mr-5 p-2 px-6 rounded-lg font-bold bg-white text-black">
                        {item.quantity}
                      </span>
                      <button
                        className="actionBtn rounded-full px-2"
                        onClick={() => decrementQuantity(item.id, item.serving)}
                        disabled={item.quantity === 0}
                      >
                        <MinusOutlined />
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <div className="flex flex-col justify-center items-center">
            <h2 className="headline-1 section-title text-center mb-10 pt-10">
              No Items in Cart
            </h2>
            <Button className="actionBtn ml-10 mb-10" onClick={backToHome}>
              Back to Home
            </Button>
          </div>
        )}

        {cart.length > 0 && totalBill >= 1 && (
          <>
            <Tabs
              className="cart-tabs"
              defaultActiveKey="1"
              items={[
                {
                  key: "1",
                  label: "Pick-up",
                },
                {
                  key: "2",
                  label: "Delivery",
                },
              ]}
              onChange={(key) => setOrderType(key - 1)}
            />

            <div className="flex justify-center">
              <Form form={form} layout="vertical" className="mt-8 w-[50%]">
                {orderType === 1 && (
                  <div className="input-address">
                    <Form.Item
                      name="address"
                      label="Address"
                      className="first-input"
                      rules={[
                        {
                          required: true,
                          message: "Please input your address!",
                        },
                      ]}
                    >
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleAddressChange}
                        onSelect={handleAddressSelect}
                        searchOptions={{
                          componentRestrictions: { country: ["ie"] },
                          bounds: westmeathBounds,
                        }}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          cashOnDeliveryLoader,
                          payNowLoader,
                        }) => (
                          <div>
                            <Input
                              {...getInputProps({
                                placeholder: "Search Places ...",
                              })}
                              className="w-full px-3 py-2 text-gray-800 bg-white outline-none focus:border-indigo-600 shadow-sm rounded-xl"
                            />
                            <div className="autocomplete-dropdown-container">
                              {(cashOnDeliveryLoader || payNowLoader) && (
                                <div className="text-white">Loading...</div>
                              )}
                              {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                  ? "suggestion-item--active"
                                  : "suggestion-item";
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: "#fafafa",
                                      cursor: "pointer",
                                    }
                                  : {
                                      backgroundColor: "#ffffff",
                                      cursor: "pointer",
                                    };
                                return (
                                  <div
                                    key={index}
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Item>
                    <Form.Item name="flat" label="Flat Number">
                      <Input
                        placeholder="Flat Number"
                        className="w-full px-3 py-2 text-gray-800 bg-white outline-none focus:border-indigo-600 shadow-sm rounded-xl"
                      />
                    </Form.Item>
                  </div>
                )}

                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Phone Number"
                    className="w-full px-3 py-2 text-gray-800 bg-white outline-none focus:border-indigo-600 shadow-sm rounded-xl"
                  />
                </Form.Item>

                <div className="payment-container mt-8">
                  {orderType === 1 && (
                    <h2 className="flex title-3 text-center" style={{ fontFamily: 'forum' }}>
                      <span className="color-yellow">
                        Delivery Charges: &nbsp;
                      </span>
                      €{deliveryCharges}
                    </h2>
                  )}
                  <h2 className="flex title-3 text-center" style={{ fontFamily: 'forum' }}>
                    <span className="color-yellow">
                      Total Bill{" "}
                      {console.log('showDiscountText', showDiscountText, totalBill, discountDetail.amount)}
                      {showDiscountText && totalBill && discountDetail.amount
                        ? `( after ${discountDetail.discount}% discount )`
                        : ""}{" "}
                      : &nbsp;
                    </span>
                    €{totalBill}
                  </h2>
                </div>

                <div className="flex justify-center items-center mt-8">
                  <div className="flex justify-center items-center">
                    <Button
                      className="actionBtn ml-10 flex py-4"
                      size="large"
                      loading={payNowLoader}
                      disabled={isDisabled}
                      onClick={() => handleSubmit(1)}
                    >
                      Pay now
                    </Button>
                    <Button
                      className="actionBtn ml-10 flex py-4"
                      size="large"
                      loading={cashOnDeliveryLoader}
                      disabled={isDisabled}
                      onClick={() => handleSubmit(0)}
                    >
                      {orderType === 1 ? "Cash on delivery" : "Pay on pick-up"}
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
          </>
        )}

        <img
          src="/static/frontend/images/shape-5.png"
          width="921"
          height="1036"
          loading="lazy"
          alt="shape"
          className="shape shape-2 move-anim"
        />
        <img
          src="/static/frontend/images/shape-6.png"
          width="343"
          height="345"
          loading="lazy"
          alt="shape"
          className="shape shape-3 move-anim"
        />
      </div>
    </section>
  );
};
