import React, { useState, useEffect } from "react";
import { Form, Modal, message, Radio, Checkbox } from "antd";
// import CustomInput from "../formElements/InputField";

function MenuModal({ modal, setIsMenuOpen, cart, setCart, item }) {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedValues, setSelectedValues] = useState({});
  const [serving, setServing] = useState(1);
  const [subItem, setSubItem] = useState(null);
  const [spiceLevel, setSpiceLevel] = useState(null);
  const [notes, setNotes] = useState("");

  const [checkedValues, setCheckedValues] = useState([]);

  useEffect(() => {
    if (item?.sub_menu_items?.length > 0) {
      setSubItem(item.sub_menu_items[0]);
    }
    if (item && item.required_items && !item.allow_serving) {
      const initialSelections = item.required_items.reduce((acc, req) => {
        if (req?.item && req.item.length > 0) {
          acc[req.id] = req.item[0].id;
        }
        return acc;
      }, {});
      setSelectedValues(initialSelections);
    }
  }, [item]);

  const handleChange = (id, value) => {
    setSelectedValues((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleRadioChange = (e) => {
    const selectedSubItem = item.sub_menu_items.find(sub => sub.id === e.target.value);
    console.log('Item33', selectedSubItem)
    setSubItem(selectedSubItem);
  };

  const handleCheckboxChange = (id) => {
    setCheckedValues((prev) => {
      if (prev.includes(id)) {
        return prev.filter((item) => item !== id);
      } else {
        return [...prev, id];
      }
    });
  };

  const toggleModal = () => {
    setIsMenuOpen((prev) => !prev);
  };

  const addToCart = () => {
    const additional_items = [...checkedValues];
    const required_items = [...Object.values(selectedValues)];
    // console.log('Item222', item, subItem)
    const itemToAdd = subItem ? { ...item, ...subItem } : { ...item };
    // console.log('Item444', itemToAdd)
    const updatedCart = [
      ...cart,
      {
        ...itemToAdd,
        quantity: 1,
        additional_items,
        required_items,
        serving,
        spiceLevel,
        notes,
      },
    ];
    setCart(updatedCart);
    console.log({ updatedCart });
    setServing(1);
    setSpiceLevel(null);
    setIsMenuOpen(false);
    setSubItem(null)
    messageApi.open({
      type: "success",
      content: "Item added to cart.",
    });
  };

  const returnPrice = (servingList, serving) => {
    if (servingList) {
      const findServing = servingList.find(
        (singleServing) => singleServing.name === serving
      );
      return findServing?.price;
    }
  };

  return (
    <Modal
      open={modal}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            addToCart(values);
            console.log('Values', values);
          })
          .catch((err) => {
            console.log(err);
          });
      }}
      onCancel={toggleModal}
      okButtonProps={{
        className: "actionBtn",
      }}
      okText="Add to Cart"
    >
      {contextHolder}
      <Form
        form={form}
        onFinish={addToCart}
        className="mt-8 w-[90%] mx-auto"
        layout="vertical"
      >
        <div className="text-white">
          {item?.sub_menu_items?.length > 0 && (
            <>
              <h2 className="title-1 text-center mt-3 mb-3" style={{
                fontFamily: 'Forum'
              }}>Select {item?.name} Type</h2>
              <Form.Item
              >
                <Radio.Group
                  onChange={handleRadioChange}
                  value={subItem?.id}
                  className="" style={{
                    padding: '15px',
                    borderRadius: '25px',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    flexWrap: 'wrap',
                    paddingBottom: '5px !important',
                    borderColor: '#e4c590'
                  }}
                >
                  {item?.sub_menu_items?.map((subItem) => (
                    <Radio
                      key={subItem?.id}
                      value={subItem?.id}
                      className="text-white text-2xl italic"
                      style={{
                        border: '1px solid',
                        padding: '15px',
                        borderRadius: '25px',
                        width: '47%',
                        marginBottom: '10px'
                      }}
                    >
                      <span>{subItem?.name}</span>
                      <small style={{ color: '#e4c590' }}>€{subItem?.price}</small>
                    </Radio>
                  ))}
                </Radio.Group>
              </Form.Item>
              {/* ))} */}
            </>
          )}
          {item?.allow_serving && (
            <>
              <h2 className="title-1 text-center mt-3 mb-3" style={{
                fontFamily: 'Forum'
              }}>Select Serving</h2>
              <Form.Item className=""
              >
                <div style={{
                  display: 'flex',
                  justifyContent: 'space-evenly'
                }}>
                  <Checkbox
                    onChange={() => { setSelectedValues({}); setServing(1) }}
                    checked={serving === 1}
                    className="text-white  text-2xl italic"
                    style={{
                      border: '1px solid',
                      padding: '15px',
                      borderRadius: '25px',
                      width: '45%',
                      marginBottom: '10px'
                    }}
                  >
                    <div className="flex" style={{
                      flexDirection: 'column'
                    }}>
                      <span className="flex">Regular &nbsp;</span>
                      <small style={{ color: '#e4c590' }}>€{returnPrice(item?.servings, "Regular")}</small>
                    </div>
                  </Checkbox>
                  {/* </Form.Item>

              <Form.Item className="flex mb-0"> */}
                  <Checkbox
                    onChange={() => setServing(2)}
                    checked={serving === 2}
                    className="text-white  text-2xl italic flex"

                    style={{
                      border: '1px solid',
                      padding: '15px',
                      borderRadius: '25px',
                      width: '45%',
                      marginBottom: '10px'
                    }}
                  >

                    <div className="flex" style={{
                      flexDirection: 'column'
                    }}>
                      <span className="flex">Large</span>
                      <small style={{ color: '#e4c590' }}>€{returnPrice(item?.servings, "Large")}</small>
                    </div>
                  </Checkbox>
                </div>
              </Form.Item>
            </>
          )}
          {item?.required_items?.length > 0 && (!item?.allow_serving || serving === 2) && (
            <>
              <h2 className="title-1 text-center mt-3 mb-3">Required Items</h2>
              {item?.required_items?.map((req) => (
                <Form.Item
                  key={req?.id}
                  label={
                    <span className="text-white title-2 mt-1" style={{ fontFamily: "Forum" }}>
                      {req?.description}
                    </span>
                  }
                  style={{
                    // border: '1px solid',
                    padding: '10px',
                    borderRadius: '25px',
                    borderColor: '#e4c590',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap'
                  }}
                >
                  <Radio.Group
                    onChange={(e) => handleChange(req?.id, e.target.value)}
                    value={selectedValues[req?.id]}
                    className="text-white w-[70%] mx-auto flex flex-col justify-start"
                    style={{
                      width: '100%',
                      padding: '15px'
                    }}
                  >
                    {req?.item?.map((el) => (
                      <Radio
                        key={el?.id}
                        value={el?.id}
                        className="text-white text-2xl italic"
                        style={{
                          border: '1px solid',
                          padding: '15px',
                          borderRadius: '25px',
                          marginBottom: '10px'
                        }}
                      >
                        {el?.name}
                        {/* {el?.price && ` - €€{el?.price}`} */}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              ))}
            </>
          )}
          {item?.show_spice && (
            <div style={{
              // border: '1px solid',
              padding: '10px',
              borderRadius: '25px',
              borderColor: '#e4c590',
              display: 'flex',
              flexDirection: 'column'
            }}>
              <span className="text-white title-2 mt-1" style={{
                fontFamily: "Forum",
                paddingBottom: '10px',
              }}>
                Spice Level
              </span>
              <div className="flex" style={{ justifyContent: 'space-evenly' }}>
                <Form.Item
                >
                  <div style={{ display: 'flex' }}>
                    <Checkbox
                      onChange={() => setSpiceLevel(1)}
                      checked={spiceLevel === 1}
                      className="text-white  text-2xl italic"
                      style={{
                        borderRadius: '25px',
                        padding: '5px',
                        border: '1px solid #599540',
                        marginLeft: '15px'
                      }}
                    >
                      <img width={70} src='/static/frontend/images/mild-icon.png' />
                      <div className="flex" style={{ justifyContent: 'space-evenly' }}>
                        <span className="flex">Mild&nbsp;</span>
                        {/* <span>€{returnPrice(item?.servings, "Regular")}</span> */}
                      </div>
                    </Checkbox>
                    <Checkbox
                      onChange={() => setSpiceLevel(2)}
                      checked={spiceLevel === 2}
                      className="text-white  text-2xl italic flex"

                      style={{
                        borderRadius: '25px',
                        padding: '5px',
                        border: '1px solid #f39400',
                        marginLeft: '15px'
                      }}
                    ><img width={70} src='/static/frontend/images/medium-icon.png' />

                      <div className="flex" style={{ justifyContent: 'space-evenly' }}>
                        <span className="flex">Medium&nbsp;</span>
                        {/* <span>€{returnPrice(item?.servings, "Large")}</span> */}
                      </div>
                    </Checkbox>
                    <Checkbox
                      onChange={() => setSpiceLevel(3)}
                      checked={spiceLevel === 3}
                      className="text-white  text-2xl italic flex"
                      style={{
                        borderRadius: '25px',
                        padding: '5px',
                        border: '1px solid #c03813',
                        marginLeft: '15px'
                      }}
                    ><img width={70} src='/static/frontend/images/hot-icon.png' />
                      <div className="flex" style={{ justifyContent: 'space-evenly' }}>
                        <span className="flex" >Spicy&nbsp;</span>
                        {/* <span>€{returnPrice(item?.servings, "Large")}</span> */}
                      </div>
                    </Checkbox>
                  </div>
                </Form.Item>

                {/* <Form.Item className="flex mb-0"
                >

                </Form.Item>
                <Form.Item className="flex mb-0"
                // style={{
                //   borderRadius: '25px',
                //   padding: '5px',
                //   border: '1px solid #c03813'
                // }}
                >

                </Form.Item> */}
              </div>
            </div>
          )}
          {/* {(item?.optional_items?.length > 0 || item?.allow_serving) && (
            <>
              <h2 className="title-1 text-center mt-5 mb-3">Optional Items</h2>
              <div className="flex flex-col gap-2 justify-start w-[70%] mx-auto">
                {item?.optional_items?.map((opt) => (
                  <Form.Item key={opt?.id} className="mb-0">
                    <Checkbox
                      onChange={() => handleCheckboxChange(opt?.id)}
                      checked={checkedValues.includes(opt?.id)}
                      className="text-white text-2xl italic"
                    >
                      {opt.name} - €{opt.price}
                    </Checkbox>
                  </Form.Item>
                ))}

                {item?.allow_serving && (
                  <>
                    <Form.Item className="flex mb-0">
                      <Checkbox
                        onChange={() => setServing(1)}
                        checked={serving === 1}
                        className="text-white  text-2xl italic"
                      >
                        <div className="flex">
                          <span className="flex">Regular: &nbsp;</span>
                          <span>€{returnPrice(item?.servings, "Regular")}</span>
                        </div>
                      </Checkbox>
                    </Form.Item>

                    <Form.Item className="flex mb-0">
                      <Checkbox
                        onChange={() => setServing(2)}
                        checked={serving === 2}
                        className="text-white  text-2xl italic flex"
                      >
                        <div className="flex">
                          <span className="flex">Large: &nbsp;</span>
                          <span>€{returnPrice(item?.servings, "Large")}</span>
                        </div>
                      </Checkbox>
                    </Form.Item>
                  </>
                )}
                {/* {item?.show_salad && (
                  <Form.Item className="flex mb-0">
                    <Checkbox
                      onChange={() => handleCheckboxChange("addSalad")}
                      checked={checkedValues.includes("addSalad")}
                      className="text-white  text-2xl italic"
                    >
                      <span className="flex">
                        Salad <img className="ml-3" width={30} src="/static/frontend/images/salad.png" />
                      </span>
                    </Checkbox>
                  </Form.Item>
                )}
                {item?.show_sauce && (
                  <Form.Item className="flex mb-0">
                    <Checkbox
                      onChange={() => handleCheckboxChange("addSauce")}
                      checked={checkedValues.includes("addSauce")}
                      className="text-white  text-2xl italic"
                    >
                      <span className="flex">
                        Sauce <img className="ml-3" width={30} src="/static/frontend/images/ketchup.png" />
                      </span>
                    </Checkbox>
                  </Form.Item>
                )} */}
          {/*</div>
            </>
          )} */}
        </div>

        <h2 className="title-1 text-center mt-3 mb-3">Notes (Optional)</h2>

        <textarea
          style={{ border: "1px solid #fff" }}
          onChange={(e) => setNotes(e.target.value)}
          className="w-full h-32 p-2 border-4 text-white border-white rounded-md focus:outline-none"
          placeholder="Write your notes here..."
        ></textarea>
      </Form>
    </Modal>
  );
}

export default MenuModal;
