import React, { useEffect, useState } from "react";
import { Table, Modal, Divider, message, Form, Button } from "antd";
import { getOderList, refundOrder } from "../api/other";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CustomInput from "../components/formElements/InputField";
import { Footer } from "../components/footer";
import { RedoOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Order Type",
    dataIndex: "orderType",
  },
  {
    title: "Payment Method",
    dataIndex: "paymentMethod",
  },
  {
    title: "Order Status",
    dataIndex: "orderStatus",
  },
  {
    title: "Date & Time",
    dataIndex: "dateTime",
  },
  {
    title: "Amount",
    dataIndex: "amount",
  },
];

export const OrderHistory = () => {
  const [loading, setLoading] = useState(true);
  const [listData, setListData] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [activeOrder, setActiveOrder] = useState({});
  const [open, setOpen] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const [showFund, setShowFund] = useState(false);
  const [showRefundInput, setShowRefundInput] = useState(false);
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const returnOrderStatus = (type) => {
    let status = "";
    switch (type) {
      case 0:
        status = "Pending";
        break;
      case 1:
        status = "In progress";
        break;
      case 2:
        status = "Out for delivery";
        break;
      case 3:
        status = "Delivered";
        break;
      case 4:
        status = "Paid";
        break;
      case 5:
        status = "Reimbursed";
        break;
      case 6:
        status = "Refund Requested";
        break;
    }
    return status;
  };

  async function getOrders() {
    try {
      const response = await getOderList();
      const list = [];
      response.map((singleOrder, index) => {
        list.push({
          key: singleOrder.id,
          orderType: singleOrder.order_type === 1 ? "Delivery" : "Pick-up",
          paymentMethod:
            singleOrder.payment_method === 1
              ? "Online Payment"
              : "Cash on delivery",
          orderStatus: returnOrderStatus(singleOrder.order_status),
          dateTime: `${moment(singleOrder.timestamp).format("YYYY-MM-DD")} , ${moment(singleOrder.timestamp).format("h:mm A")}`,
          amount: `€ ${(
            singleOrder.total_amount - singleOrder.discount_amount
          ).toFixed(2)}`,
        });
      });
      setLoading(false);
      setListData(list);
      setAllOrders(response);
    } catch (err) {
      console.log("err", err);
      navigate("/");
    }
  }

  const refundOrderHandler = async (reason) => {
    setRefunding(true);
    try {
      const res = await refundOrder({
        orderId: activeOrder.id,
        reasonForRefund: reason ?? "no reason",
      });
      await getOrders();
      messageApi.open({
        type: "success",
        content: res.message
          ? res.message
          : "Your request to reimbursement is recived",
      });
    } catch (err) {
      const msg = err?.response?.data?.error ?? "Something went wrong";
      messageApi.open({
        type: "error",
        content: msg,
      });
    }
    setRefunding(false);
    setOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    getOrders();
  }, []);

  const onClickHandler = (record) => {
    const findOrder = allOrders.find((order) => order.id === record.key);
    console.log("findOrder", findOrder);
    setActiveOrder(findOrder);
    setOpen(true);
    if (findOrder.order_status === 3 || findOrder.order_status === 4) {
      setShowFund(true);
    } else {
      setShowFund(false);
    }
    setShowRefundInput(false);
  };

  const paginationConfig = {
    pageSize: 5, // Number of items per page
    showSizeChanger: false, // Disable size changer
    hideOnSinglePage: true, // Hide pagination if there's only one page
    onChange: (page, pageSize) => {
      // You can handle page change logic here if needed
    },
  };

  const requestRefundHandler = () => {
    if (!showRefundInput) {
      setShowRefundInput(true);
      return;
    }
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        refundOrderHandler(values);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="section">
      {contextHolder}
      <footer id="aboutus" className="footer section has-bg-image text-center">
        <div className="max-w-full">
          <div className="footer-top p-[20px]">
            <h2 className="headline-1 text-center mb-30">Order History</h2>

            {loading ? (
              <span>Loading ...</span>
            ) : (
              <section
                className="p-0 has-before has-after max-w-full"
                id="aboutus"
              >
                <div className="form max-w-full bg-black-10">
                  <div
                    className="max-w-full"
                    style={{
                      backgroundImage: `url('/static/frontend/images/form-pattern.png')`,
                    }}
                    id="timings"
                  >
                    <Table
                      dataSource={listData}
                      columns={columns}
                      rowHoverable={true}
                      className="transparent-table order-history"
                      onRow={(record, rowIndex) => {
                        return {
                          onClick: (event) => {
                            onClickHandler(record);
                          },
                        };
                      }}
                      pagination={paginationConfig}
                    />
                  </div>
                </div>
              </section>
            )}
          </div>
          <Footer />
        </div>
      </footer>
      {activeOrder ? (
        <Modal
          title=""
          centered
          open={open}
          onOk={() => {
            setOpen(false);
          }}
          onCancel={() => {
            setOpen(false);
          }}
          className="order-history-modal"
        >
          <div className="container">
            <ul className="flex-list">
              {activeOrder?.order_items?.map((item) => {
                return (
                  <li key={item.id}>
                    <div className="menu-card hover:card"
                      style={{
                        border: 'solid 1px',
                        color: '#e4c590',
                        borderRadius: '25px',
                        padding: '5px'
                      }}>
                      <figure
                        className="card-banner img-holder"
                        style={{ width: 100, height: 100 }}
                      >
                        <img
                          src={
                            item?.menu_item_image
                              ? item?.menu_item_image
                              : "/static/frontend/images//menu-1.png"
                          }
                          width="100"
                          height="100"
                          loading="lazy"
                          alt={item.name}
                          className="img-cover"
                        />
                      </figure>
                      <div className="flex justify-between ">
                        <div>
                          <div className="title-wrapper">
                            <h3 className="title-3">
                              <a href="#" className="card-title" style={{ fontFamily: 'sans-serif' }}>
                                {item.menu_item_name}
                              </a>
                            </h3>
                            <span className="span title-2">€{item.amount}</span>
                          </div>
                          <div className="d-flex">
                            <h3 className="title-3 color-yellow">
                              Quantity:&nbsp;
                            </h3>
                            <h3 className="title-3">{item.quantity}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <Divider className="order-history-divider" plain></Divider>
            <div className="order-detail">
              <div className="d-flex">
                <h3 className="title-3 color-yellow">* Order type:&nbsp;</h3>
                <h3 className="title-3">
                  {activeOrder.order_type === 1 ? "Delivery" : "Pick-up"}
                </h3>
              </div>
              <div className="d-flex">
                <h3 className="title-3 color-yellow">* Payment type:&nbsp;</h3>
                <h3 className="title-3">
                  {activeOrder.payment_method === 1
                    ? "Online Payment"
                    : "Cash on delivery"}
                </h3>
              </div>
              <div className="d-flex">
                <h3 className="title-3 color-yellow">* Order Status:&nbsp;</h3>
                <h3 className="title-3">
                  {returnOrderStatus(activeOrder.order_status)}
                </h3>
              </div>
              <div className="d-flex">
                <h3 className="title-3 color-yellow">* Total amount:&nbsp;</h3>
                <h3 className="title-3">
                  €
                  {(
                    activeOrder.total_amount - activeOrder.discount_amount
                  ).toFixed(2)}
                </h3>
              </div>
            </div>
            <Divider className="order-history-divider" plain></Divider>
            <div className="refund-container">
              {showRefundInput && showFund ? (
                <Form
                  className="w-70"
                  form={form}
                  onFinish={refundOrderHandler}
                >
                  <Form.Item
                    name="refund"
                    className="w-full"
                    rules={[
                      {
                        required: true,
                        message: "Please input your reason!",
                      },
                    ]}
                  >
                    <CustomInput labelText="Reason for refund" type="text" />
                  </Form.Item>
                </Form>
              ) : null}
              {showFund && (
                <Button
                  className="request-refund"
                  type="primary"
                  icon={<RedoOutlined />}
                  onClick={requestRefundHandler}
                >
                  {refunding
                    ? "Loading ..."
                    : showRefundInput
                      ? "Submit"
                      : "Request refund"}
                </Button>
              )}
            </div>
            {showRefundInput && (
              <Divider className="order-history-divider" plain></Divider>
            )}
          </div>
        </Modal>
      ) : null}
    </div>
  );
};