import { axiosGet, axiosPost, axiosTempPost } from "../../utils/axiosMethod";

export const aboutRestaurant = async () => {
  const res = await axiosGet({
    path: "restaurant/about/",
  });

  return res?.data;
};

export const menuRestaurant = async () => {
  const res = await axiosGet({
    path: "menu/",
  });

  return res?.data;
};

export const getDiscountDetails = async () => {
  const res = await axiosGet({
    path: "restaurant/configuration/DiscountConfig/",
    token: true,
  });

  return res?.data;
};

export const getDeliveryDetails = async () => {
  const res = await axiosGet({
    path: "restaurant/configuration/DeliveryConfig/",
    token: true,
  });

  return res?.data;
};

export const bookTable = async (payload) => {
  const res = await axiosPost({
    path: "reservation/",
    payload,
    token: true,
  });

  return res;
};

export const publishOrder = async (payload) => {
  const res = await axiosPost({
    path: "publish-order/",
    payload,
    token: true,
  });

  return res?.data;
};

export const createPaymentIntent = async (payload) => {
  const res = await axiosTempPost({
    path: "payments/create-payment-intent/",
    payload,
    token: true,
  });

  return res?.data;
};

export const getOderList = async () => {
  const res = await axiosGet({
    path: "orders",
    token: true,
  });

  return res?.data;
};

export const refundOrder = async (payload) => {
  const res = await axiosPost({
    path: `request-reimbursement/${payload.orderId}/`,
    payload: {
      reason_for_refund: payload.reasonForRefund,
    },
    token: true,
  });

  return res?.data;
};

export const updateOrderStatus = async (payload) => {
  const res = await axiosPost({
    path: "update-order-status/",
    payload,
    token: true,
  });

  return res?.data;
};

export const getDeliveryChargeDetails = async () => {
  const res = await axiosGet({
    path: "restaurant/configuration/DeliveryChargeConfig/",
    token: true,
  });

  return res?.data;
};
