import { jwtDecode } from "jwt-decode";

const checkTokenExpiration = () => {
  const token = localStorage.getItem("token");

  if (!token) {
    return false; // No token in localStorage
  }

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Current time in seconds

    if (decodedToken.exp < currentTime) {
      localStorage.removeItem("token");
      return true; // Token is expired and removed
    }

    return false; // Token is not expired
  } catch (error) {
    console.error("Error decoding token:", error);
    localStorage.removeItem("token");
    return true; // Token is invalid and removed
  }
};

export default checkTokenExpiration;
