import React, { useState, useEffect, useRef, useContext } from "react";
import { Badge } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import LoginModal from "./modals/loginModal";
import SignupModal from "./modals/signupModal";
import { AuthenticationContext } from "./layout/layoutWrapper";
import ForgotPasswordModal from "./modals/forgotPassword";

export const Header = ({ totalItems }) => {
  const location = useLocation();
  const value = useContext(AuthenticationContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();
  const handleCart = () => {
    navigate("/cart");
  };

  const navbarRef = useRef(null);
  const overlayRef = useRef(null);
  const headerRef = useRef(null);
  const lastScrollPos = useRef(0);

  const toggleNavbar = () => {
    setIsMenuOpen((prev) => !prev);
    document.body.classList.toggle("nav-active", !isMenuOpen);
    navbarRef.current?.classList.toggle("active", !isMenuOpen);
    overlayRef.current?.classList.toggle("active", !isMenuOpen);
  };

  const handleScroll = () => {
    if (headerRef.current) {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos > lastScrollPos.current;

      if (currentScrollPos > 50) {
        headerRef.current.classList.add("active");
        if (isScrollingDown) {
          headerRef.current.classList.add("hide");
        } else {
          headerRef.current.classList.remove("hide");
        }
      } else {
        headerRef.current.classList.remove("active");
        headerRef.current.classList.remove("hide");
      }

      lastScrollPos.current = currentScrollPos;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTo = (targetId, timer) => {
    setTimeout(() => {
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop,
          behavior: "smooth",
        });
      }
    }, timer);
  };
  const handleNavLinkClick = (e) => {
    e.preventDefault();
    const targetId = e.currentTarget.getAttribute("href");
    if (targetId === "#orderHistory") {
      return navigate("/order-history");
    }
    if (targetId === "#logout") {
      if (localStorage.getItem("token") !== null) {
        localStorage.removeItem("token");
      }
      return navigate("/");
    }
    if (location.pathname !== "/home") {
      navigate("/");
      handleScrollTo(targetId, 500);
    } else {
      handleScrollTo(targetId, 0);
    }
    setIsMenuOpen(false);
    document.body.classList.remove("nav-active");
    navbarRef.current?.classList.remove("active");
    overlayRef.current?.classList.remove("active");
  };

  const renderLogo = () => (
    <a href="/" className="logo pl-3">
      <img
        src="/static/frontend/images/web-logo.jpeg"
        width="100"
        height="45"
        style={{borderRadius: '40%'}}
        alt="Mughali - Home"
      />
    </a>
  );

  const renderModals = () => (
    <>
      {value.loginModal && (
        <LoginModal
          modal={value.loginModal}
          setLoginModal={value.setLoginModal}
          setSignupModal={value.setSignupModal}
          setForgotPasswordModal={value.setForgotPasswordModal}
        />
      )}
      {value.signupModal && (
        <SignupModal
          modal={value.signupModal}
          setSignupModal={value.setSignupModal}
          setLoginModal={value.setLoginModal}
        />
      )}
      {value.forgotPasswordModal && (
        <ForgotPasswordModal
          modal={value.forgotPasswordModal}
          setLoginModal={value.setLoginModal}
          setForgotPasswordModal={value.setForgotPasswordModal}
        />
      )}
    </>
  );

  return (
    <header className="header" data-header ref={headerRef}>
      {renderModals()}
      <div className="container sm:max-w-full">
        {renderLogo()}
        <div className="relative left-[-20px] flex flex-row-reverse gap-3 items-center">
          <button
            className="nav-open-btn"
            aria-label="open menu"
            onClick={toggleNavbar}
          >
            <span className="line line-1"></span>
            <span className="line line-2"></span>
            <span className="line line-3"></span>
          </button>
          <div className="cartIcon xs:opacity-100">
            <Badge count={totalItems} className="mt-4" onClick={handleCart}>
              <ShoppingCartOutlined className="z-[300]" />
            </Badge>
          </div>
        </div>
        <nav
          className={`navbar ${isMenuOpen ? "active" : ""}`}
          data-navbar
          ref={navbarRef}
        >
          <button
            className="close-btn"
            aria-label="close menu"
            onClick={toggleNavbar}
          >
            <ion-icon name="close-outline" aria-hidden="true"></ion-icon>
          </button>
          {renderLogo()}
          <ul className="navbar-list">
            <li className="navbar-item">
              <a
                href="#home"
                onClick={handleNavLinkClick}
                className="navbar-link hover-underline active"
              >
                <div className="separator"></div>
                <span className="span">Home</span>
              </a>
            </li>
            <li className="navbar-item">
              <a
                href="#menu"
                onClick={handleNavLinkClick}
                className="navbar-link hover-underline"
              >
                <div className="separator"></div>
                <span className="span">Menus</span>
              </a>
            </li>
            <li className="navbar-item">
              <a
                href="#reservation"
                onClick={handleNavLinkClick}
                className="navbar-link hover-underline"
              >
                <div className="separator"></div>
                <span className="span">Booking</span>
              </a>
            </li>

            <li className="navbar-item">
              <a
                href="#about"
                onClick={handleNavLinkClick}
                className="navbar-link hover-underline"
              >
                <div className="separator"></div>
                <span className="span">Our Strength</span>
              </a>
            </li>
            <li className="navbar-item">
              <a
                href="#aboutus"
                onClick={handleNavLinkClick}
                className="navbar-link hover-underline"
              >
                <div className="separator"></div>
                <span className="span">About Us</span>
              </a>
            </li>
            {localStorage.getItem("token") && (
              <>
                <li className="navbar-item">
                  <a
                    href="#orderHistory"
                    onClick={handleNavLinkClick}
                    className="navbar-link hover-underline"
                  >
                    <div className="separator"></div>
                    <span className="span">Order History</span>
                  </a>
                </li>
                <li className="navbar-item">
                  <a
                    href="#logout"
                    onClick={handleNavLinkClick}
                    className="navbar-link hover-underline"
                  >
                    <div className="separator"></div>
                    <span className="span">Logout</span>
                  </a>
                </li>
              </>
            )}
            {localStorage.getItem("token") ? null : (
              <li className="navbar-item">
                <a
                  onClick={() => {
                    value.setSignupModal(true);
                    setIsMenuOpen(false);
                    document.body.classList.remove("nav-active");
                    navbarRef.current?.classList.remove("active");
                    overlayRef.current?.classList.remove("active");
                  }}
                  className="navbar-link hover-underline"
                >
                  <div className="separator"></div>
                  <span className="span">Sign Up</span>
                </a>
              </li>
            )}
          </ul>
        </nav>
        {/* <div className="overlay" onClick={toggleNavbar} data-overlay ref={overlayRef}></div> */}
      </div>
    </header>
  );
};
