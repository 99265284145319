import React, { useEffect } from "react";

const Preloader = () => {
  useEffect(() => {
    const preloader = document.querySelector("[data-preload]");

    const handleLoad = () => {
      preloader.classList.add("loaded");
      document.body.classList.add("loaded");
    };

    if (document.readyState === "complete") {
      handleLoad();
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return (
    <div className="preload" data-preload>
      <div className="circle"></div>
      <p className="text">Mughali</p>
    </div>
  );
};

export default Preloader;
