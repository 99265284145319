import React from "react";

const DeliveryIcon = ({ icon, text }) => {
  return (
    <div className="fixed z-40 right-0 top-1/2 transform -translate-y-1/2 flex items-center bg-red-800 text-white rounded-l-full p-3 shadow-lg">
      {/* <span className="mr-2">{icon}</span> */}
      <span className="flex gap-2">
        <img src="/static/frontend/images/delivery-yellow.png" width={40} height={30} />
      </span>
      <div className="flex gap-4 justify-center items-center">
        <span>{text}</span>
        {/* <span className="flex gap-2">
          <img src="/static/frontend/images/halal-icon.png" width={30} height={30} />
          <p>Halal</p>
        </span> */}
      </div>
    </div>
  );
};

export default DeliveryIcon;
