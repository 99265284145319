import React, { createContext, useContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { Topbar } from "../topbar";
import { Header } from "../header";
import { Footer } from "../footer";

export const AuthenticationContext = createContext(null);

export default function LayoutWrapper({ totalItems, aboutData }) {
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [forgotPasswordModal, setForgotPasswordModal] = useState(false);
  return (
    <AuthenticationContext.Provider
      value={{
        loginModal,
        setLoginModal,
        signupModal,
        setSignupModal,
        forgotPasswordModal,
        setForgotPasswordModal,
      }}
    >
      <Topbar totalItems={totalItems} aboutData={aboutData} />
      <Header totalItems={totalItems} />
      <Outlet />
    </AuthenticationContext.Provider>
  );
}
