import React, { useEffect } from "react";

import { HeroSlider } from "../components/heroSlider";
import { MenuItems } from "../components/menuItems";
import { Reservation } from "../components/reservation";
import { StrengthSection } from "../components/strength";
import { AboutUs } from "../components/about-us";
import DeliveryIcon from "../components/DeliveryIcon";
import { updateOrderStatus } from "../api/other";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

export const Home = ({
  setTotalItems,
  aboutData,
  deliveryDetails,
  sessionId,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (sessionId) {
      async function updateOrderStatusHandler() {
        try {
          const response = await updateOrderStatus({
            session_key: sessionId,
          });
          messageApi.open({
            type: "success",
            content: response?.message,
          });
          const cleanUrl = location.pathname + "?" + queryParams.toString();
          navigate(cleanUrl, { replace: true });
        } catch (err) {
          console.log("err", err);
        }
      }
      updateOrderStatusHandler();
    }
    queryParams.delete("session_id");
  }, [sessionId]);

  return (
    <>
      {contextHolder}
      <HeroSlider />
      <main className="flex flex-col justify-center">
        <article>
          <MenuItems setTotalItems={setTotalItems} />
          <Reservation aboutData={aboutData} />
          <StrengthSection />
        </article>
      </main>
      <AboutUs aboutData={aboutData} />
      <DeliveryIcon text={deliveryDetails && `${deliveryDetails} min`} />
      <a
        href="#top"
        className="back-top-btn active"
        aria-label="back to top"
        data-back-top-btn
      >
        <ion-icon name="chevron-up" aria-hidden="true"></ion-icon>
      </a>
    </>
  );
};
